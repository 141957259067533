<template>
  <div class="row">
    <div class="col-12">
        <div class="card card-body">
            <h3>طلبات اليوم</h3>
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            الشركة
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            تاريخ الخروج
                        </th>
                        <th>
                            الاجمالي
                        </th>
                        <th>
                            الحالة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders_1" :key="order._id" @click="current = order" v-b-modal.modal-1>
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.company.title }}
                            </td>
                            <td>
                                {{ order.days[0] }} ({{order.days.length-1}} يوم)
                            </td>
                            <td>
                                {{ order.days[order.days.length - 1] }}
                            </td>
                            <td>
                                {{ order.total_reseller + order.extra }} ريال
                            </td>
                            <td>
                                <span v-if="!order.cash_done" class="text-warning">لم يتم التحصيل</span>
                                <span v-if="order.cash_done" class="text-success"><i class="fa fa-check"></i> تم التحصيل</span>
                                <span v-if="order.company_calc">
                                    <br>
                                    <i class="fa fa-check-square text-success"></i>
                                    {{ order.cash_notes }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card card-body">
            <h3>طلبات الغد</h3>
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            الشركة
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            تاريخ الخروج
                        </th>
                        <th>
                            الاجمالي
                        </th>
                        <th>
                            الحالة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders_2" :key="order._id" @click="current = order" v-b-modal.modal-1>
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.company.title }}
                            </td>
                            <td>
                                {{ order.days[0] }} ({{order.days.length-1}} يوم)
                            </td>
                            <td>
                                {{ order.days[order.days.length - 1] }}
                            </td>
                            <td>
                                {{ order.total_reseller + order.extra }} ريال
                            </td>
                            <td>
                                <span v-if="!order.cash_done" class="text-warning">لم يتم التحصيل</span>
                                <span v-if="order.cash_done" class="text-success"><i class="fa fa-check"></i> تم التحصيل</span>
                                <span v-if="order.company_calc">
                                    <br>
                                    <i class="fa fa-check-square text-success"></i>
                                    {{ order.cash_notes }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
     <b-modal id="modal-1" size="sm" title="تحصيل" hide-footer>
        <div class="col-12 text-center">
            <h4 class="text-center col-12 g">
                تأكيد تحصيل الحجز رقم {{ current.id }} <br><br>بمبلغ {{ current.total_reseller + current.extra }} ريال
            </h4>
            <br>
            <button class="btn btn-success" @click="done()" v-if="!current.cash_done">
                <i class="fa fa-check-square"></i>
                تحصيل الحجز الآن
            </button>
            <hr>
            اتصال بالعميل:
            <br><br>
            <a :href="'tel:' + current.phone" class="btn btn-primary btn-block">
                <i class="fa fa-phone"></i>
                {{ current.phone }}
            </a>
        </div>
     </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,BFormCheckbox,
    },
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders_1: [],
            orders_2: [],
            loading: false,
            page: 0,
            current: {},
            reason: "",
            q: null,
            date: null,
            status: null,
        }
    },
    created(){
        var g = this;
        this.getOrders();
    },
    methods: {
        getOrders(){
            var g = this;
            g.loading = true;
            $.post(api + '/cash/orders/index', {
                jwt: this.jwt,
                page: this.page,
                date: new Date().toISOString().split("T")[0],
                q: this.q,
                status: this.status
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders_1 = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
            var s = new Date();
            s.setDate(s.getDate() + 1)
            $.post(api + '/cash/orders/index', {
                jwt: this.jwt,
                page: this.page,
                date: s.toISOString().split("T")[0],
                q: this.q,
                status: this.status
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders_2 = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        },
        done(){
            var g = this;
            g.loading = true;
            $.post(api + '/cash/orders/done', {
                jwt: this.jwt,
                id: this.current._id
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getOrders();
                    $("#refresh-balance").click();
                    $("#modal-1___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        },
    }
}
</script>

<style>

</style>